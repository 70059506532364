"use strict";

const $ = require("./main.js");

var _defaultLat = -40.849313;
var _defaultLng = 174.751636;
var _defaultZoomLevel = 6;

$(document).ready(function ($) {
    const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBZ1TAMWw1nXJWDPTpMvIcVKWvH14KHe6U&callback=initMap`;
    const mapsScript = document.createElement('script');
    mapsScript.async = true;
    mapsScript.src = mapsUrl;
    document.querySelector('head').appendChild(mapsScript);

    $(document).on('click', 'body', function(e) {
        if ($(e.target).closest('.js-filter-item').length !== 1) {
            $('.js-map-toggle-button').removeClass('active');
            $('.js-map-toggle-target').removeClass('active');
        }
    });

    $(document).on('click', '.js-map-toggle-button', function(e) {
        $('.js-map-toggle-button').not(this).removeClass('active');
        $(this).toggleClass('active');

        $('.js-map-toggle-target').removeClass('active');
        if ($(this).hasClass('active')) {
            getLocation();
            $($(this).data('target')).addClass('active');
        }

        return false;
    });

    $(document).on('click', '.js-community-list-map-toggle', function (e) {
        e.preventDefault();
        $(this).toggleClass('map-button-active');
        let locationValue = $(".js-current-location").val();
        if ($(this).hasClass('map-button-active')) {
            history.pushState(null, null, `/retirement-villages/${locationValue ? locationValue + '/' : ''}map${location.search}`);
            $('#js-community-list-view').hide();
            $('#js-community-map-view').show();
        } else {
            history.pushState(null, null, `/retirement-villages${locationValue ? '/' + locationValue : ''}${location.search}`);
            $('#js-community-list-view').show();
            $('#js-community-map-view').hide();
            initLazyLoad();
        }
    });

    $(document).on('change', '.js-map-form-option', function (e) {
        if ($(this).hasClass('js-map-form-loc')) {
            updateLocationByFilter();
        }

        var query = $('.js-map-form').serialize();
        history.pushState(null, null, location.pathname + (query ? '?' + $('.js-map-form').serialize() : ''));
        queryData();
    });

    $(document).on('click', '.js-map-form-tag', function (e) {
        console.log($(this));
        var id = $(this).data('id');
        $('#' + id).prop("checked", false);

        if ($(this).hasClass('js-map-form-tag-remove')) {
            updateLocationByFilter()
        }

        var query = $('.js-map-form').serialize();
        history.pushState(null, null, location.pathname + (query ? '?' + $('.js-map-form').serialize() : ''));
        queryData();
    });
});

function initMap() {
    window._map = new google.maps.Map(document.getElementById("map"), {
        zoom: _defaultZoomLevel,
        center: {
            lat: _defaultLat,
            lng: _defaultLng
        },
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        disableDefaultUI: true,
        scrollwheel: false,
        styles: [{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#4b8cc7"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"landscape","stylers":[{"color":"#d5e7f7"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#d5e7f7"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#d5e7f7"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#b7d4ee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#b7d4ee"}]}],
    });

    $(document).on('click', '.js-map-zoom-out', function () {
        window._map.setZoom(window._map.getZoom() - 1);
    });

    $(document).on('click', '.js-map-zoom-in', function () {
        window._map.setZoom(window._map.getZoom() + 1);
    });

    updateMarkers();

    getLocation();
};
window.initMap = initMap;

function queryData() {
    let locationValue = $(".js-current-location").val();
    $.ajax({
        type: 'GET',
        url: `/retirement-villages/filter${locationValue ? '/' + locationValue : ''}${location.search}`,
        success: function (data) {
            render(data)
        }
    });
};

function render(data) {
    if (data.communities.length == 0) {
        $('.js-map-empty').show();
    } else {
        $('.js-map-empty').hide();
    }

    if ($('.js-list-reset').length == 0) {
        $('.js-map-form').after('<ul class="js-list-reset"></ul>')
    }

    if (data.filters.length > 0) {
        $('.js-list-reset').show();
        $('.js-list-reset').replaceWith(data.tagsHtml);
    } else {
        $('.js-list-reset').remove();
    }
    $('.js-filter-count__number').text(data.communities.length + ' ' + (data.communities.length == 1 ? 'community' : 'communities'))
    $('.js-community-list-view-items').html(data.communitiesHtml);
    initLazyLoad();
    updateMarkers();
};

function updateMarkers() {
    if (typeof window._markers !== 'undefined') {
        for (var idx in window._markers) {
            var itm = window._markers[idx];
            itm.setMap(null);
        }
    }

    window._markers = [];
    window._infos = [];

    var SnazzyInfoWindow = require('snazzy-info-window');
    var locations = [];
    $.each($('.js-community-map-marker'), function (idx, itm) {
        locations.push({
            latitude: $(itm).data('latitude'),
            longitude: $(itm).data('longitude'),
            inforWindowHtml: $(itm).html(),
        })
    })
    var markers = [];
    for (var i = 0; i < locations.length; i++) {
        var location = locations[i];
        var marker = new google.maps.Marker({
            map: window._map,
            position: new google.maps.LatLng(location.latitude, location.longitude),
            icon: {
                url: "/images/map-marker.svg"
            },
            _idx: i,

        });
        var infoWindow = new SnazzyInfoWindow({
            marker: marker,
            border: false,
            borderRadius: '3px',
            shadow: false,
            closeWhenOthersOpen: true,
            wrapperClass: 'branch-info-window',
            placement: 'top',
            content: location.inforWindowHtml,
            openOnMarkerClick: false,
            panOnOpen: false,
        });
        $('body').append(`<div id="infopop-${i}" class="modal--map-infowindow" style="display: none">${location.inforWindowHtml}</div>`);

        marker.addListener('click', function () {
            window._map.setCenter(this.getPosition());
            if ($(window).width() >= 700) {
                window._infos[this._idx].open();
            } else {
                $.fancybox.open({
                    src: '#infopop-' + this._idx,
                    type: 'inline',
                    touch: false,
                });
            }
        });

        window._markers.push(marker);
        window._infos.push(infoWindow);

        markers.push(marker);
    }
};

function getLocation() {
    if ($('.js-map-form-loc.active').length > 0) {
        updateLocationByFilter();
    }
}

function updateLocationByFilter() {
    for (var idx in window._infos) {
        var itm = window._infos[idx];
        itm.close();
    }

    var lat = _defaultLat;
    var lng = _defaultLng;
    var zoom = _defaultZoomLevel;

    if ($('.js-map-form-loc.active').length == 1) {
        lat = $('.js-map-form-loc.active').data('lat');
        lng = $('.js-map-form-loc.active').data('lng');
        zoom = $('.js-map-form-loc.active').data('zoom');

        if (!lat || !lng || !zoom) {
            var lat = _defaultLat;
            var lng = _defaultLng;
            var zoom = _defaultZoomLevel;
        }
    }

    window._map.setZoom(zoom);
    window._map.setCenter({
        lat: lat,
        lng: lng
    });
}
